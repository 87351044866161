
.board {

  .board-row {
    display: flex;

    .board-cell {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 80%;
      }

      &.board-figure-1 {
        background-color: azure;
      }

      &.board-figure-2 {
        background-color: bisque;
        cursor: pointer;
      }

      &.board-figure-3 {
        background-color: lightslategray;
        cursor: pointer;
      }

      &.from-move {
        background-color: orange;
      }


      &.pick-move {
        background-color: purple;
      }

      &.to-move {
        background-color: green;
      }
    }
  }


}

.table {
  display: flex;

  @Media (max-width: 800px) {
    flex-direction: column;
  }

  .table-move {
    margin-left: 20px;
  }

  .table-figures {
    margin-top: 10px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}



.table2 {
  overflow: auto;
  height: 200px;
  background: #ccc;
  .tr {
    display: block;
  }

  .tr-inline {
    display: inline-block;
    width: 300px;
    border: 1px solid grey;
    margin: 2px;
    padding: 2px;
  }

  .td:first-child {
    width: 300px;
    display: block;
  }

  .td {
    display: inline-block;
    width: 150px;
    text-align: center;
  }
}